$(document).ready(function () {

    $('.js-save-company').on('click', function (e) {

        e.preventDefault();

        valid = true;
        error_msg = "";

        form = $(this).closest('form, .js-form');

        error_msg += form.checkRequiredFields();

        if (valid) {
            post_data = form.find('input, select, textarea').serialize();

            url = "../php/ajax/company.php?action=save";

            $.post(url, post_data, function (data) {

                try {

                    response = JSON.parse(data);

                    console.log(response);

                    if (response.valid) {
                        location.href = "/companies";
                    } else {
                        valid = false;
                        error_msg += response.error_message;
                        msg = errorify(error_msg);
                        dialogify(msg);
                    }
                } catch (e) {
                    valid = false;
                    error_msg += "<li>Technical error: " + data + "</li>";
                    msg = errorify(error_msg);
                    dialogify(msg);
                }

            });

        }

        if (!valid) {
            msg = errorify(error_msg);
            dialogify(msg);
        }


    });

    $('.js-edit-company').on('click', function (e) {
        e.preventDefault();
        id = $(this).attr('data_id');
        location.href = '/company/' + id;
    });

    $('.js-delete-company').on('click', function (e) {

        e.preventDefault();

        valid = true;
        error_msg = "";

        message = "<h2>Are you sure you want to delete this?</h2><p>Any related records will be removed.</p>";

        id = $('.js-company-id').val();

        options = {
            buttons: {
                'Yes': function () {
                    //$(this).dialog("close");
                    post_data = {id: id};
                    url = "../php/ajax/company.php?action=delete";

                    $.post(url, post_data, function (data) {

                        try {

                            response = JSON.parse(data);

                            console.log(response);

                            if (response.valid) {
                                location.href = "/companies";
                            } else {
                                valid = false;
                                error_msg += response.error_message;
                                msg = errorify(error_msg);
                                dialogify(msg);
                            }
                        } catch (e) {
                            valid = false;
                            error_msg += "<li>Technical error: " + data + "</li>";
                            msg = errorify(error_msg);
                            dialogify(msg);
                        }

                    });
                },
                'No': function () {
                    $(this).dialog("close");
                },
            }
        };

        dialogify(message, options);

    });

});