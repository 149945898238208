$(document).ready(function () {

    initialiseDatePicker();

    //Check forms for required fields
    $.fn.checkRequiredFields = function () {

        form = this;
        msg = "";

        //check required fields
        form.find('.required, .js-required').each(function () {

            placeholder = $(this).attr('placeholder');

            if (!placeholder || placeholder == '' || placeholder == ' ') {
                placeholder = $(this).closest('.input').find('.input__label').text();
            }

            if ($(this).is('select')) {
                value = $(this).find(':selected').val();
            } else {
                value = $(this).val();
            }

            if (!value && ((($(this).is(':visible') || $(this).attr('type') == "hidden") && $(this).parent().is(':visible')) || ($(this).hasClass('autocomplete') && $(this).parent().is(':visible'))) || (value == placeholder)) {

                if ($(this).attr('name') == 'files_container') {
                    wrapper = $(this).closest('div.file');

                    if (wrapper.find('ul > li').length == 0) {
                        msg += "<li>" + placeholder + " appears to be empty</li>";
                    }
                } else {
                    msg += "<li>" + placeholder + " appears to be empty</li>";
                }
            }

        });

        if (msg != "") {
            valid = false;
        }

        return msg;
    }


    //Capture return key from forms
    $('form').keypress(function (e) {

        if (e.which == 13) {

            if (!$(e.target).hasClass('wysiwyg') && !$(e.target).is('textarea')) {
                $(this).find('button').not('.file button, .files button, .mce-tinymce button').trigger('click');
                return false;
            }

        }

    });

    $('.js-select-box').select2();

});

function initialiseDatePicker(mondays_only = 0) {

    console.log('initialiseDatePicker');

    $('.js-datepicker').datepicker("destroy");

    var options = {dateFormat: 'dd/mm/yy'};

    if (mondays_only) {
        $.extend(options, {
            beforeShowDay: function (date) {
                console.log(date.getDay());
                if (date.getDay() == 1) {
                    return [true, ''];
                } else {
                    return [false, ''];
                }
            }
        });
    }

    $('.js-datepicker').datepicker(options);
}